@import 'src/assets/styles/variables';

.calendar-wrap {
  display: flex;
  height: calc(100vh - 94px);

  &__container {
    position: relative;
    flex: 1;

    &--hidden {
      display: none;
    }

    table,
    .fc-timegrid-body,
    .fc-daygrid-body {
      width: 100% !important;
    }
  }

  .fc-v-event {
    border-width: 4px !important;
    border-radius: 8px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0 !important;
  }

  .fc-v-event {
    background-color: rgba(188, 232, 241, 0.3);
    border-color: rgba(188, 232, 241, 0.3);
    box-shadow: none;
  }

  .fc-event-time {
    color: $black;
  }
  .fc-list-event-title {
    vertical-align: middle;
  }

  .main-calendar-item {
    color: $white;

    &.light {
      color: $black;
    }
  }
  .main-calendar-item .fc-event-time {
    color: inherit;
  }
  .main-calendar-item-agenda {
    color: $black;
  }
  .main-calendar-item-agenda .fc-event-time {
    color: $black;
  }
  .fc-event-main {
    color: inherit;
  }

  .calendar-item-title {
    line-height: 14px;

    &__dscr {
      display: flex;

      span {
        margin-right: 30px;
      }
    }
  }
}

.calendar-container {
  position: relative;
  flex: 1;
}

.calendar-controls {
  padding: 2px;
  &__today-btn {
    margin: 0 2px;
  }

  .ant-select {
    margin-right: 10px;
  }
}
