@import 'src/assets/styles/variables';

.calendar-tooltip {
  position: absolute;
  padding: 10px;
  z-index: 1000;
  max-width: 600px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 0 3px 0 $black;
  transform: translateY(-100%);

  &__name {
    font-weight: bold;
  }

  &__value {
    width: 100%;
    overflow: hidden;
    * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
